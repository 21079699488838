import Head from "next/head";
import { useContext } from "react";
import { useRouter } from "next/router";

import metaTH from "./meta-th.json";
import metaEN from "./meta-en.json";

import { I18nContext } from "../I18N";
import { get } from "lodash";

import { useDynamicCanonicalUrl } from "../../hooks/useDynamicCanonicalUrl";

export default function Meta() {
  //hooks
  const router = useRouter();
  const { langCode } = useContext(I18nContext);
  const { canonicalUrl, enCanonicalUrl, thCanonicalUrl, addMetaNoIndex } =
    useDynamicCanonicalUrl();

  return (
    <Head>
      <title>
        {langCode === "th"
          ? get(
              metaTH,
              [router.asPath, "title"],
              "hibro | Easy healthcare, man to man"
            )
          : get(
              metaEN,
              [router.asPath, "title"],
              "hibro | Easy healthcare, man to man"
            )}
      </title>
      <meta
        name="description"
        content={
          langCode === "th"
            ? get(
                metaTH,
                [router.asPath, "desc"],
                "บริการดูแลสุขภาพชายโดยผู้เชี่ยวชาญที่เข้าใจคุณ พบแพทย์ออนไลน์ ตรวจและรักษาอย่างต่อเนื่อง สะดวกสบายเป็นส่วนตัว ฟรีค่าจัดส่ง"
              )
            : get(
                metaEN,
                [router.asPath, "desc"],
                "บริการดูแลสุขภาพชายโดยผู้เชี่ยวชาญที่เข้าใจคุณ พบแพทย์ออนไลน์ ตรวจและรักษาอย่างต่อเนื่อง สะดวกสบายเป็นส่วนตัว ฟรีค่าจัดส่ง"
              )
        }
        data-react-helmet="true"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="image"
        content="https://sayhibro5d98f21dfb374d31b878033166abec6d182612-prod.s3.ap-southeast-1.amazonaws.com/seoImage.jpg"
        data-react-helmet="true"
      />
      <meta
        property="og:type"
        name="type"
        content="article"
        data-react-helmet="true"
      />
      <meta
        property="og:url"
        name="url"
        content={canonicalUrl}
        data-react-helmet="true"
      />
      <meta
        property="og:title"
        name="title"
        content={
          langCode === "th"
            ? get(
                metaTH,
                [router.asPath, "title"],
                "hibro | Easy healthcare, man to man"
              )
            : get(
                metaEN,
                [router.asPath, "title"],
                "hibro | Easy healthcare, man to man"
              )
        }
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        name="description"
        content={
          langCode === "th"
            ? get(
                metaTH,
                [router.asPath, "desc"],
                "บริการดูแลสุขภาพชายโดยผู้เชี่ยวชาญที่เข้าใจคุณ พบแพทย์ออนไลน์ ตรวจและรักษาอย่างต่อเนื่อง สะดวกสบายเป็นส่วนตัว ฟรีค่าจัดส่ง"
              )
            : get(
                metaEN,
                [router.asPath, "desc"],
                "บริการดูแลสุขภาพชายโดยผู้เชี่ยวชาญที่เข้าใจคุณ พบแพทย์ออนไลน์ ตรวจและรักษาอย่างต่อเนื่อง สะดวกสบายเป็นส่วนตัว ฟรีค่าจัดส่ง"
              )
        }
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        name="image"
        content="https://sayhibro5d98f21dfb374d31b878033166abec6d182612-prod.s3.ap-southeast-1.amazonaws.com/seoImage.jpg"
        data-react-helmet="true"
      />
      <meta
        name="twitter:card"
        content="summary_large_image"
        data-react-helmet="true"
      />
      <meta
        name="twitter:title"
        content={
          langCode === "th"
            ? get(
                metaTH,
                [router.asPath, "title"],
                "hibro | Easy healthcare, man to man"
              )
            : get(
                metaEN,
                [router.asPath, "title"],
                "hibro | Easy healthcare, man to man"
              )
        }
        data-react-helmet="true"
      />
      <meta
        name="twitter:description"
        content={
          langCode === "th"
            ? get(
                metaTH,
                [router.asPath, "desc"],
                "บริการดูแลสุขภาพชายโดยผู้เชี่ยวชาญที่เข้าใจคุณ พบแพทย์ออนไลน์ ตรวจและรักษาอย่างต่อเนื่อง สะดวกสบายเป็นส่วนตัว ฟรีค่าจัดส่ง"
              )
            : get(
                metaEN,
                [router.asPath, "desc"],
                "บริการดูแลสุขภาพชายโดยผู้เชี่ยวชาญที่เข้าใจคุณ พบแพทย์ออนไลน์ ตรวจและรักษาอย่างต่อเนื่อง สะดวกสบายเป็นส่วนตัว ฟรีค่าจัดส่ง"
              )
        }
        data-react-helmet="true"
      />
      <meta
        name="twitter:image"
        content="https://sayhibro5d98f21dfb374d31b878033166abec6d182612-prod.s3.ap-southeast-1.amazonaws.com/seoImage.jpg"
        data-react-helmet="true"
      />
      <meta
        property="fb:app_id"
        content="560604011641316"
        data-react-helmet="true"
      />
      <meta
        name="google-site-verification"
        content="aoDV0F-xPmfvnmFWVuVzWGRDB5C3rueQIrRoiRegRq8"
      />
      <meta
        name="facebook-domain-verification"
        content="dut2pbpprooie10vv5oooohpu8ziyj"
      />
      {addMetaNoIndex && <meta name="robots" content="noindex, nofollow" />}
      <link rel="canonical" href={canonicalUrl} />
      <link rel="alternate" href={thCanonicalUrl} hrefLang="th" />
      <link rel="alternate" href={enCanonicalUrl} hrefLang="en" />
      <link rel="icon" type="image/icon" href="/favicon.ico" />
      <link rel="shortcut icon" type="image/icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;500;600;700;800&display=swap"
        rel="stylesheet"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff"></meta>
      <script src="/js/heic2any.js" async />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1616519548698641');
            fbq('track', 'PageView');
            `,
        }}
      />
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-C4LRHNLK74"
      />
      {/**
       * Code for hotjar tracking
       */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2545701,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
        }}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KGBLG33');
            `,
        }}
      />
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KGBLG33"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=1616519548698641&ev=PageView&noscript=1"
        />
      </noscript>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '400318178561566');
            fbq('track', 'PageView');
            `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style="display:none"
          src="https://www.facebook.com/tr?id=400318178561566&ev=PageView&noscript=1"
        />
      </noscript>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.__lc = window.__lc || {};
            window.__lc.license = 13043106;
            ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
            `,
        }}
      />
      <noscript>
        <a
          href="https://www.livechatinc.com/chat-with/13043106/"
          rel="nofollow"
        >
          Chat with us
        </a>
        , powered by{" "}
        <a
          href="https://www.livechatinc.com/?welcome"
          target="_blank"
          rel="noreferrer"
        >
          LiveChat
        </a>
      </noscript>
    </Head>
  );
}
