import { useRouter } from "next/router";

const getPathFromUrl = (url) => {
  const pathUrl = url.split(/[?#]/)[0];
  const lastCharacter = pathUrl.slice(-1);
  return lastCharacter === "/" ? pathUrl.slice(0, -1) : pathUrl;
};

export const useDynamicCanonicalUrl = () => {
  //hooks
  const router = useRouter();

  //url data
  const route = router.route;

  //blog chil url
  const blogChildUrlPath = `/blog/${router.query.id}`;
  const isBlogChildRoute = route === "/blog/[id]";

  //general url
  const generalUrlPath = router.asPath;
  const isHaveUrlPath = generalUrlPath && generalUrlPath !== "/";
  const urlPath = isBlogChildRoute ? blogChildUrlPath : generalUrlPath;
  const canonicalUrlPath = isHaveUrlPath ? urlPath : "";

  //locale domain
  const locale = router.locale;
  const domainLocales = router.domainLocales;
  const getDomain = (lang) => {
    const domain = domainLocales.find((local) => {
      return local.defaultLocale === lang;
    }).domain;
    const localeDomain = lang !== "en" ? `www.${domain}` : domain;

    return localeDomain;
  };
  const domain = getDomain(locale);
  const enDomain = getDomain("en");
  const thDomain = getDomain("th");

  //canonical url
  const canonicalUrl = `https://${domain}${canonicalUrlPath}`;
  const enCanonicalUrl = `https://${enDomain}${canonicalUrlPath}`;
  const thCanonicalUrl = `https://${thDomain}${canonicalUrlPath}`;

  //noIndex validation
  const isEnBlog = (route === "/blog" || isBlogChildRoute) && locale === "en";
  const isBlogCat = route === "/blog/cat/[id]";
  const addMetaNoIndex = isEnBlog || isBlogCat;

  return {
    canonicalUrl: getPathFromUrl(canonicalUrl),
    enCanonicalUrl: getPathFromUrl(enCanonicalUrl),
    thCanonicalUrl: getPathFromUrl(thCanonicalUrl),
    addMetaNoIndex,
  };
};
